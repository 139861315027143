.bgDark{
  background-color: #151929;
  min-height: 100vh;
  width: 100vw;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
}

.loginFormFlex{
  display: flex;
  flex-direction: column;
  gap: 1em;
  min-width: 320px;
  width: 100%;
  max-width: 350px;
}

.normalHeading{
  font-size: 20px;
  font-weight: 500;
}

.loginHeading{
  color: #42B883;
}

.greenColor{
  color: #42B883;
}


.greenButton{
  background-color: #42B883;
  color: #fff;
  padding: 0.5rem 2rem;
  border-radius: 98px;
  border: none;
  font-size: 20px;
  font-weight: 600;
  width: 100%;
}

.greenButton:disabled{
  background-color: #7a7a7a;
}

.greenInput{
  font-size: 20px;
  font-weight: 500;
  color: #42B883;
  background-color: #151929;
  border: 1px solid #42B883;
  line-height: 27px;
  padding: 0rem 1rem 0.5rem 1rem;
  margin-top: 6px;
}


.passwordProgressBar::before{
  content: "";
  width: 100%;
  position: absolute;
  height: 15px;
  border: 1px solid #42B883;
  border-radius: 20px;
}

.passwordProgressBar{
  height: 15px;
  width: 0%;
  background: rgb(21,25,41);
  background: linear-gradient(90deg, rgba(21,25,41,1) 10%, rgba(66,184,131,1) 90%, rgba(255,255,255,1) 100%);
  border-radius: 20px;
  transition: all 300ms ease-out;
}


.iconToolTip{
  color: #42B883 ;
  border: 2px solid #42B883;
  border-radius: 20px;
  padding: 2px 5px;
}

.toolTip{
  width: 300px;
  color: #42B883 !important;
  background-color: #151929 !important;
}


