.container{
  display: flex;
  justify-content: start;
  flex-wrap: wrap;
  align-items: center;
  gap: 1rem;
  flex-wrap: wrap;
  margin: 1rem 0;
}

.image{
  width: 85px;
  height: 85px;
  border-radius: 50%;
  object-fit: cover;
}

.contactInfo{
  display: flex;
  flex-direction: column;
  color: #42b883;
}


.contactInfo span:first-child{
  font-size: calc(1rem + 0.4vw);
}

.contactListActionsContainer{
  margin-bottom: 16px;
}

.contactListActionsContainer button{
  background-color: transparent;
  border: none;
}


.colorGreen{
  color: #42b883;
}
.colorRed{
  color: #b84242;
}