.bgDark{
  background-color: #151929;
  height: 100vh;
  width: 100vw;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
}

.loginFormFlex{
  display: flex;
  flex-direction: column;
  gap: 1em;
  min-width: 320px;
  width: 100%;
  max-width: 350px;
}

.normalHeading{
  font-size: 20px;
  font-weight: 500;
}

.loginHeading{
  color: #42B883;
}

.greenColor{
  color: #42B883;
}

.image{
  width: 300px;
  margin: auto;
}


.greenButton{
  background-color: #42B883;
  color: #fff;
  padding: 0.5rem 2rem;
  border-radius: 98px;
  border: none;
  font-size: 20px;
  font-weight: 600;
  width: 100%;
}

.greenInput{
  font-size: 20px;
  font-weight: 500;
  color: #42B883;
  background-color: #151929;
  border: 1px solid #42B883;
  line-height: 27px;
  padding: 0rem 1rem 0.5rem 1rem;
  margin-top: 6px;
}
