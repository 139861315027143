.container{
  color: #42b883;
  padding-top: 3rem;
}

.deleteAccount{
  background-color: transparent;
  border: 2px solid #42B883;
  color: #42B883;
  padding: 0.3rem 2rem;
  border-radius: 98px;
  font-size: 20px;
  font-weight: 600;
}

.deleteAccount:hover{
  background-color: #42B883;
  color: #151929;
}