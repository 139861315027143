.container{
  display: flex;
  justify-content: center;
  align-items:start;
  padding-top: 3rem;
  margin: 0 auto;
}



.addContactContainer{
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  align-items: center;
}


.heading{
  color: #42b883;
}

.addButton{
  background-color: transparent;
  border: 2px solid #42B883;
  color:#42B883 ;
  padding: 0.3rem 2rem;
  border-radius: 98px;
  font-size: 20px;
  font-weight: 600;
}

.addButton:hover{
  background-color: #42B883;
  color: #151929;
}

.greenInput{
  width: 240px;
  font-size: 20px;
  font-weight: 500;
  color: #42B883;
  background-color: #151929;
  border: 1px solid #42B883;
  line-height: 27px;
  padding: 0rem 1rem 0.5rem 1rem;
}

.contactListContainer{
  display: flex;
  flex-direction: column;
  padding: 2rem 0;
  width: 100%;
}

.spinner{
  color: #42B883;
  width: 30px;
}