*{
  transition: all 300ms ease-out;
}


::-webkit-scrollbar {
  width: 5px;
}
  ::-webkit-scrollbar-track {
  background: #151929; 
}
  ::-webkit-scrollbar-thumb {
  background: #42b883; 
}
::-webkit-scrollbar-thumb:hover {
  
  background: #42b883; 
} 
