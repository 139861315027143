
.title{
  color: #42B883;
}


.fixedContainer{
  position: fixed;
  width: 100%;
  height: 100vh;
  background-color: #151929f7;
  top: 100%;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.isOpen{
  top: 0 !important;
}

.container{
  width: 90%;
  max-height: 500px;
  margin: auto;
  padding: 1rem;
  overflow-y: scroll;
  overflow-x: hidden;
  margin-top: 25vh;
  border: 1px solid #42b883;
  position: relative;
  border-radius: 4px;
 
}


.fixedContainerCloseButton{
  position: absolute;
  top: 0;
  right: 0px;
  width: 30px;
  height: 30px;
  border-bottom: 1px solid #42b883;
  border-left: 1px solid #42b883;
  border-top: none;
  border-right: 1px solid #42b883;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 0px 0px 4px 4px; /*TL TR BR BL*/
  color: #42b883;
  background-color: transparent;
}



/* @media screen and ( max-width: 500px) {
  .chartContainer{
    min-width: 400px;
    height: 250px;
  }
}

@media screen and ( max-width: 370px) {
  .chartContainer{
    min-width: 240px;
    height: 250px;
  }
}

@media screen and ( min-width: 1000px) {
  .chartContainer{
    min-width: 500px;
    height: 250px;
  }
} */

