.container{
  padding: 1rem 1rem 0 1rem ;
  color: #fff;
}
.menu{
  display: flex ;
  flex-direction: column;
  gap: 1rem;
  list-style: none;
  padding-left: 0;
  margin-top: 2rem;
}

.menu li a{
  display: flex;
  justify-content: start;
  align-items: center;
  text-decoration: none;
  color: #cfcfcf;
  padding: 0 3rem 0 1rem;
  height: 32px;
  font-size: 16px;
}

.currentMenu{
  background-color: #42B883 ;
  color: #fff !important;
}

.menuIcon{
  padding-right: 1rem;
  min-width: 26px;
}

.menuToggler{
  background-color: #151929;
  color: #42B883;
  border: 2px solid #42B883;
}

.hide{
  display: none;
}

.menuHide li a {
  padding: 0 !important;
  justify-content: center;
}

.menuHide li a span svg{
  padding: 0 !important;
  display: flex;
  justify-content: center;
  align-items: center;
}
