
.fixedContainer{
  position: fixed;
  width: 100%;
  height: 100vh;
  background-color: #151929f7;
  top: 100%;
  /* top: 0; */
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;

}

.isOpen{
  top: 0 !important;
}

.container{
  width: 90%;
  max-height: 500px;
  margin: auto;
  padding: 1rem;
  overflow-y: scroll;
  overflow-x: hidden;
  margin-top: 25vh;
  border: 1px solid #42b883;
  position: relative;
  border-radius: 4px;
}


.closeButton{
  position: absolute;
  top: 0;
  right: 0px;
  width: 30px;
  height: 30px;
  border-bottom: 1px solid #42b883;
  border-left: 1px solid #42b883;
  border-top: none;
  border-right: 1px solid #42b883;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 0px 0px 4px 4px;
  color: #42b883;
  background-color: transparent;
}

.closeButton:hover {
  color: #151929;
  background-color: #42b883;
}

.paper{
  padding: 0.5rem;
  border-radius: 4px;
  margin: 1.5rem 0;
  color: #42b883;
  box-shadow: 0px 0px 6px 2px rgba(66,184,131,0.78);
-webkit-box-shadow: 0px 0px 6px 2px rgba(66,184,131,0.78);
-moz-box-shadow: 0px 0px 6px 2px rgba(66,184,131,0.78);
}

.smallText{
  font-size: 12px;
}

.pageButton{
  background-color: transparent;
  border: none;
  color: #42b883;
}

.smallImg{
  width: 50px;
  height: 50px;
  border-radius: 100px;
}