.container{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 1rem;
  
}

.title{
  color: #42b883;
  margin-top: 0;
  margin-bottom: 0.5rem;
  font-weight: 500;
  line-height: 1.2;
  font-size: calc(1.375rem + 0.5vw);
}
.buttonContainer{
  display: flex;
}
.buttonContainer button{
  background-color: transparent;
  border: none;
}

.colorGreen{
  color: #42b883;
}

.colorRed{
  color: #b84242 ;
}

.fixedContainer{
  position: fixed;
  width: 100%;
  height: 100%;
  background-color: #151929f7;
  top: 100%;
  left: 0;
}

.isOpen{
  top: 0 !important;
}

.groupContactsContainer{
  width: 90%;
  max-height: 500px;
  margin: auto;
  padding: 1rem;
  overflow-y: scroll;
  margin-top: 25vh;
  border: 1px solid #42b883;
  position: relative;
  border-radius: 4px;

}

.fixedContainerCloseButton{
  position: absolute;
  top: 0;
  right: 0px;
  width: 30px;
  height: 30px;
  border-bottom: 1px solid #42b883;
  border-left: 1px solid #42b883;
  border-top: none;
  border-right: 1px solid #42b883;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 0px 0px 4px 4px; /*TL TR BR BL*/
  color: #42b883;
  background-color: transparent;
}

.fixedContainerCloseButton:hover{
  color: #151929cc;
  background-color: #42b883;
}
