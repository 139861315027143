.container{
  color: #42B883;
  list-style: none;
  border: 1px solid #42B883;
  padding: 13px;
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  height: -moz-fit-content;
  height: fit-content;
  max-width: 370px;
  width: 90%;
  margin: 1rem auto;
}

.container li {
  display: flex;
  gap: 1.5rem;
  justify-content: space-between;
}


