.image{
  border-radius: 500px;
  width: 80px;
  height: 80px;
}

.title{
  color: #42b883 ;
}

