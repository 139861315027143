.container{
}

.image{
  width: 85px;
  height: 85px;
  border-radius: 50%;
  object-fit: cover;
}

.contactInfo{
  display: flex;
  flex-direction: column;
  color: #42b883;
}


.contactInfo span:first-child{
  font-size: calc(1rem + 0.4vw);
}

.contactListActionsContainer{
  margin-bottom: 16px;
}

.contactListActionsContainer button{
  background-color: transparent;
  border: none;
}

.contactListActionsContainer button:first-child,.contactListActionsContainer button:nth-child(2){
  color: #42b883;
}

.contactListActionsContainer button:nth-child(3){
  color: #b84242;
}
